import { mapState } from "vuex"

export default {
	computed: {
		...mapState("auth", {
			user: (state) => state.current,
		}),
		userRoles() {
			return this.user ? this.user.roles : []
		},
		currentLang() {
			return 'en'
		},
		currentDateLang() {
			return 'en'
		},
        isPhysician() {
            return this.userRoles.some(role => role.name === 'physician')
        },
        isAdministration() {
            return this.userRoles.some(role => role.name === 'administration')
        },
        isNurse() {
            return this.userRoles.some(role => role.name === 'nurse')
        }
	},
	methods: {
        checkUserPermission(codename, user_permission) {
        	if (this.user?.is_staff) return true // override permissions for staff user

			const permissionExists = this.userRoles
			    .flatMap(role => role.permissions.filter(permission => permission.codename)) // Filter out empty permissions
			    .some(permission => permission.codename === `${user_permission}_${codename}`)

			return permissionExists

        }
	}
}