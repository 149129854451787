import { UserProfile, Security, UserPermissions, } from "./auth"
import { MultipleInputField } from "./general"

import { BarChart } from "./charts"


import { MsgNavbarButton, MsgInboxTab } from "./messaging"
import { VcViewTemplate, VcTemplateBuilder, VcBuilder, VcNavbarButton, VcListGroupComponent } from "./viewcreator"

import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete'; // new

import {
  ValidationObserver,
  ValidationProvider,
  extend,
} from "vee-validate"

import * as rules from "vee-validate/dist/rules"
// import { numeric, double } from 'vee-validate/dist/rules';

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('measurement', {
  validate: value => {
		 // Convert the value to a string to handle different input types
		  const valueStr = value.toString();

		  // Check if the value is an integer
		  if (Number.isInteger(Number(value))) {
		    return true;
		  }

		  // Check if the value is a double with one decimal place
		  const oneDecimalPlacePattern = /^\d+\.\d{1}$/
		  if (oneDecimalPlacePattern.test(valueStr)) {
		    return true
		  }

		  // Check if the value is a double with two decimal places
		  const twoDecimalPlacesPattern = /^\d+\.\d{2}$/
		  if (twoDecimalPlacesPattern.test(valueStr)) {
		    return true
		  }

		  // If none of the conditions match, the value is invalid
		  return false
  },

  message: 'The {_field_} field must be a number with one or two decimal places'
})

import moment from 'moment'
extend('is_not_greator', (value, [otherValue]) => {
    const time1 = moment(value, 'HH:mm:ss')
    const time2 = moment(otherValue, 'HH:mm:ss')

    return time1.isBefore(time2)
}, {
  hasTarget: true
})

import { registerComponents } from "bootstrap-vue/src/utils/plugins"

const components = {
	UserProfile,
	Security,
	UserPermissions,
	VcViewTemplate,
	VcTemplateBuilder,
	VcBuilder,
	VcNavbarButton,
	VcListGroupComponent,

	VueBootstrapAutocomplete,
	ValidationObserver,
	ValidationProvider,
	MultipleInputField,
	
	BarChart,

	MsgNavbarButton,
	MsgInboxTab
}

export default {
	install(Vue) {
		registerComponents(Vue, components)
	}
}
