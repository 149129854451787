import moment from "moment"

export default {
	data() {
		return {
			currentDateTime: null
		}
	},
	computed: {
		getWeekdays() {
			// console.log(moment.weekdays().map(w => w))
			// const days = moment.weekdays().map((v,i) => [String(i),v])
			// console.log(days)

			return moment.weekdays()
		}
	},
	methods: {
		toFullDate(date) {
			return moment(date).locale(this.currentDateLang).format('DD MMMM YYYY')
		},
		toLocaleDate(date) {
			return moment(date).locale(this.currentDateLang).format('DD MMM YYYY')
		},
		toLocaleDateTime(datetime) {
			return moment(datetime).locale(this.currentDateLang).format('DD MMM YYYY HH:mm')
		},
		toLocaleTimeFromDateTime(datetime) {
			return moment(datetime).locale(this.currentDateLang).format('HH:mm')
		},
		toLocaleTime(time) {
			return moment(time, "HH:mm:ss").locale(this.currentDateLang).format('HH:mm')
		},
		dateToLocaleTime(date) {
			return moment(date).locale(this.currentDateLang).format('HH:mm')
		},
		toWeekday(date) {
			return moment(date).locale(this.currentDateLang).format('dddd')
		},
		toAgo(datetime) {
			return moment(datetime).locale(this.currentDateLang).fromNow()
		},
		dynamicDateTime(datetime) {
			
			if (datetime) {
				var now = moment()
			// if (moment(datetime).year() === now.year()) {
			// 	console.log(moment(datetime).day() === now.day())
			// 	console.log(moment(datetime).day())
			// 	console.log(now.day())
			// 	console.log(moment(datetime))
			// 	if (moment(datetime).day() === now.day()) {
			// 		return this.toLocaleTimeFromDateTime(datetime)
			// 	}

			// 	return moment(datetime).locale(this.currentDateLang).format('DD MMM')
			// }

				return this.toLocaleDateTime(datetime)
			}
			else {
				return "N/A"
			}
		},
		timeNow() {
			var now = moment()
			return this.dateToLocaleTime(now)

		},
		dateTimeNow() {
			var now = moment()
			return this.toLocaleDateTime(now)

		}
	},
	mounted() {
		//short ago 
		moment.updateLocale("en", { 
			week: {
				dow: 1, // First day of week is Monday
			},
			relativeTime: {
				future: 'in %s',
				past: '%s ago',
				s:  'seconds',
				ss: '%ss',
				m:  'a minute',
				mm: '%dm',
				h:  'an hour',
				hh: '%dh',
				d:  'a day',
				dd: '%dd',
				M:  'a month',
				MM: '%dM',
				y:  'a year',
				yy: '%dY'
			}
		})

		moment.updateLocale("nl", { 
			week: {
				dow: 1, // First day of week is Monday
			},
			relativeTime: {
				future: 'in %s',
				past: '%s geleden',
				s:  'paar seconden',
				ss: '%ss',
				m:  'minuut',
				mm: '%dm',
				h:  'uur',
				hh: '%dh',
				d:  'dag',
				dd: '%dd',
				M:  'maand',
				MM: '%dM',
				y:  'jaar',
				yy: '%dY'
			}
		})

	    setInterval(() => {
	      this.currentDateTime = this.timeNow()
	    }, 1000)
	}
}
