import session from '@/shared/session'

const state = {
	activeLoaders: [],
	clinic: {},
	portal: {},
	survey: {},
	responses: [],
	questions: [],
	questionsOptions: [],
	step: 0,
	completed_response: {},
}

// getters
const getters = {
	isLoading: (state) => (module) => {
		return state.activeLoaders.indexOf(module) > -1
	},
	anyLoading: (state) => () => {
		return state.activeLoaders.length > 0
	},
	getLastResponse: (state) => () => {
		if(state.responses.length){
			return state.responses[0]
		}
	},
	getOrderedQuestions: (state) => () => {
		if(state.questions.length){
			return [...state.questions].sort((a, b) => a.order - b.order)
		}
	},
}

// actions
const actions = {
	nextStep({commit}){
		commit('set_next_step')
	},
	async fetchRoutes({commit}){
		const routes = await session
			.get("/api/main/dynamic-routes/")
		return routes.data
	},

// ========= Clinic ===========
	async filterClinics({ commit }, data) {
		const messages = await session
			.get("/api/main/clinics/", {
				params: data,
			})
		return messages.data
	},
	async getClinics({ commit }, data) {
		dispatch("loading/load", "get_clinics", { root: true })
		const clinics = await session
			.get("/api/main/clinics/", {
				params: data,
			})
        dispatch("loading/end", "get_clinics", { root: true })
		return clinics.data
	},
	async getClinicById({ commit }, clinic_id) {
		const clinics = await session
			.get(`/api/main/clinics/${clinic_id}/`)
		commit("set_clinic", clinics.data)
		return clinics.data
	},
	createClinic({ commit }, data) {
		return session
			.post(`/api/main/clinics/`, data)
			.then((results) => {
				return results.data
			})
	},
	async updateClinic({ commit }, data) {
		const clinics = await session
			.put(`/api/main/clinics/${data.id}/`, data)
			.then((results) => {
				return results.data
			})
	},
	async patchClinic({ commit }, data) {
		const clinics = await session
			.patch(`/api/main/clinics/${data.id}/`, data)
			.then((results) => {
				return results.data
			})
	},
	async deleteClinic({ commit }, data) {
		const clinics = await session
			.delete(`/api/main/clinics/${data.id}/`, data)
			.then((results) => {
				return results.data
			})
	},
// ========= Portal ===========
	async getPortalByClinicId({ commit }, clinic_id) {
		const clinics = await session
			.get(`/api/main/clinics/${clinic_id}/portal/`)
		return clinics.data
	},
	async updatePortalByClinicId({ commit }, data) {
		const clinics = await session
			.post(`/api/main/clinics/${data.id}/update-portal/`, data.data, {
				headers: {
	                "Content-Type": "multipart/form-data",
	            },
			})
		return clinics.data
	},
// ========= Clinic + Administrator ===========
	createClinicAdministrator({ commit }, data) {
		// Creates a clinic and administrator in one call
		return session
			.post(`/api/main/administrators/`, data)
			.then((results) => {
				return results.data
			})
	},
	async filterClinicAdministrators({ commit }, data) {
		const messages = await session
			.get("/api/main/administrators/", {
				params: data,
			})
		return messages.data
	},
// ========= Administrator ===========
	async getAdministrators({ commit }, data) {
		dispatch("loading/load", "get_administrators", { root: true })
		const administrators = await session
			.get("/api/main/administrators/", {
				params: data,
			})
        dispatch("loading/end", "get_administrators", { root: true })
		return administrators.data
	},
	async getAdministratorById({ commit }, adminId) {
		const administrators = await session
			.get(`/api/main/administrators/${adminId}/`)
		return administrators.data
	},
	createAdministrator({ commit }, data) {
		return session
			.post(`/api/main/administrators/create-clinic-admin/`, data)
			.then((results) => {
				return results.data
			})
	},
	async updateAdministrator({ commit }, data) {
		return session
			.put(`/api/main/administrators/${data.id}/`, data)
			.then((results) => {
				return results.data
			})
	},
	async resetPasswordAdministrator({ commit }, data) {
		return session
			.put(`/api/main/administrators/${data.id}/reset-password/`, data)
			.then((results) => {
				return results.data
			})
	},
	async patchAdministrator({ commit }, data) {
		return session
			.patch(`/api/main/administrators/${data.id}/`, data)
			.then((results) => {
				return results.data
			})
	},
	async deleteAdministrator({ commit }, data) {
		return session
			.delete(`/api/main/administrators/${data.id}/`, data)
			.then((results) => {
				return results.data
			})
	},
	async deactivateAdministrator({ commit }, id) {
		return session
			.put(`/api/main/administrators/${id}/deactivate/`)
			.then((results) => {
				return results.data
			})
	},
// ========= Superadmins ===========
	async createSuperadmin({ commit }, data) {
		return session
			.post(`/api/main/superadmins/`, data)
			.then((results) => {
				return results.data
			})
	},
	async updateSuperadmin({ commit }, data) {
		return session
			.put(`/api/main/superadmins/${data.id}/`, data)
			.then((results) => {
				return results.data
			})
	},
	async resetPasswordSuperadmin({ commit }, data) {
		return session
			.put(`/api/main/superadmins/${data.id}/reset-password/`, data)
			.then((results) => {
				return results.data
			})
	},
	async deactivateSuperadmin({ commit }, id) {
		return session
			.put(`/api/main/superadmins/${id}/deactivate/`)
			.then((results) => {
				return results.data
			})
	},
	async getSuperadminById({ commit }, data) {
		const superadmin = await session
			.get(`/api/main/superadmins/${data.id}/`)
		return superadmin.data
	},
	async getSuperadmins({ commit }, data) {
		return session
			.get(`/api/main/superadmins/${data.id}/`)
			.then((results) => {
				return results.data
			})
	},
	async filterSuperadmins({ commit }, data) {
		const messages = await session
			.get("/api/main/superadmins/", {
				params: data,
			})
		return messages.data
	},
// ========= Portal ===========
	async getPortalByUrl({ commit }, url) {
		console.log(url)
		const portal = await session
			.get("/api/main/portals/", {
				params: {'url': url},
			})
		commit("set_portal", portal.data)
		return portal.data
	},
// ========= Survey ===========
	async getSurveyByUrl({ commit }, url) {
		const survey = await session
			.get("/api/survey/survey/", {
				params: {'url': url},
			})
		commit("set_survey", survey.data)
		return survey.data
	},
	async getQuestions({ commit }) {
		const questions = await session
			.get("/api/survey/question/")
		commit("set_questions", questions.data)
	},
	async createAnswer({ commit }, data) {
		return session
			.post("/api/survey/answer/", data)
			.then((results) => {
				return results.data
			})
	},
	async updateResponse({ commit }, data) {
		return session
			.patch(`/api/survey/response/${data.id}/`, data)
			.then((results) => {
				return results.data
				commit("set_completed_response", results.data.completed_result)
			})
	},
	async getQuestionsOptions({ commit }) {
		const questionsOpions = await session
			.get("/api/survey/question-option/")
		commit("set_questions_options", questionsOpions.data)
	},
	async createRespondent({commit}, data) {
		return session
			.post(`/api/survey/respondent/`, data)
			.then((results) => {
				commit("set_responses", results.data.responses)
				return results.data
			})
	},
// ========= Other ===========
	load({ commit }, module) {
		commit(MUTATION_TYPE.LOAD, module)
	},

	end({ commit }, module) {
		commit(MUTATION_TYPE.END, module)
	},
	async getGroups({ commit }, data) {
		const groups = await session
			.get(`/api/main/groups/`, {
				params: data,
			})
		return groups.data
	},
}

// Base Utils
const uniq = array => {
	return array.filter((el, index, arr) => index == arr.indexOf(el))
}

const MUTATION_TYPE = {
	LOAD: 'LOAD',
	END: 'END'
}

const mutations = {
	set_clinic(state, clinic) {
		state.clinic = clinic
	},
	set_next_step(state) {
		state.step = state.step + 1
	},
	set_portal(state, portal) {
		state.portal = portal
	},
	set_survey(state, survey) {
		state.survey = survey
		state.portal = survey.portal
		state.clinic = survey.clinic
	},
	set_responses(state, responses) {
		state.responses = responses
	},
	set_completed_response(state, completed_response) {
		state.completed_response = completed_response
	},
	set_questions(state, questions) {
		state.questions = questions
	},
	set_questions_options(state, questionsOptions) {
		state.questionsOptions = questionsOptions
	},
	[MUTATION_TYPE.LOAD](state, module) {
		state.activeLoaders.push(module)
		state.activeLoaders = uniq(state.activeLoaders)
	},
	[MUTATION_TYPE.END](state, module) {
		state.activeLoaders = uniq(state.activeLoaders).filter(p => p !== module)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}