import session from '@/shared/session'

const state = {
	currentView: {
		name: null,
		template: {
			components: []
		},
		user: {
			template: {
				components: []
			}
		}
	},

	builder: {
		available_components: [],
		registered_components: [],
	}
}

// getters
const getters = {
	userHasTemplate: state => state.currentView.user.template ? state.currentView.user.template.view_name === state.currentView.name : false,

	getDefaultTemplate: (state) => () => {
		return state.currentView.template
	},
	getDefaultTemplateComponents: (state) => () => {
		return state.currentView.template.components
	},
	getUserTemplate: (state) => () => {
		return state.currentView.user.template
	},
	getUserTemplateComponents: (state) => () => {
		return state.currentView.user.template.components ? state.currentView.user.template.components : []
	},
	getAvailableComponents: (state) => () => {
		return state.builder.available_components
	},
	getRegisteredComponents: (state) => () => {
		return state.builder.registered_components
	},
}

// actions
const actions = {
	loadBuilder: ({commit, state, getters}, data) => {

        const user_components = getters.getUserTemplateComponents()
        const default_components = getters.getDefaultTemplateComponents()

		const available = default_components.filter(dc => {

			return user_components.every(parent => {
				return parent.children.every(c => c.id !== dc.id) ? dc : false
			})
		})

		commit('set_builder_available_components', available)
		commit('set_builder_registered_components', user_components)

		return state.builder
	},

	updateAvailableComponents: ({commit}, data) => {
		commit('set_builder_available_components', data)
	},

	updateRegisteredComponents: ({commit, getters}, data) => {
        const default_components = getters.getDefaultTemplateComponents()		

		const available = default_components.filter(dc => {

			return data.every(parent => {
				return parent.children.every(c => c.id !== dc.id) ? dc : false
			})
		})

		commit('set_builder_available_components', available)

		const registered = _.cloneDeep(data)

		commit('set_builder_registered_components', registered)
	},
	
	async setCurrentViewAttributes({ dispatch, commit, state, getters }, viewName) {
		dispatch("loading/load", "viewcreator", { root: true })

		const [defaultTemplate, userTemplate] = await Promise.all([
			session.get(`/api/viewcreator/views/${viewName}/`),
			session.get(`/api/viewcreator/user/${viewName}/`),
		])

		commit("set_view_name", viewName)
		commit("set_default_template", defaultTemplate.data)
		commit("set_user_template", userTemplate.data)
		
		dispatch("loading/end", "viewcreator", { root: true })

		return getters.userHasTemplate ? 'render' : 'no-template'
	},

	createUserTemplate({ dispatch, commit, state }, data) {
		dispatch("loading/load", "viewcreator", { root: true })
		return session
				.post(`/api/viewcreator/templates/`, data)
				.then(() => {
					return session.get(`/api/viewcreator/user/${state.currentView.name}/`)
				})
				.then(userTemplate => {
					commit("set_user_template", userTemplate.data)
					return userTemplate.data
				})
		      .finally(() => {
		        dispatch("loading/end", "viewcreator", { root: true })
		      })
	},

	updateUserTemplate({ dispatch, commit, state }, data) {
		dispatch("loading/load", "viewcreator", { root: true })
		return session
				.put(`/api/viewcreator/templates/${state.currentView.user.template.id}/`, data)
				.then(template => {
					return session.get(`/api/viewcreator/user/${state.currentView.name}/`)
				})
				.then(userTemplate => {
					commit("set_user_template", userTemplate.data)
					return userTemplate.data

				})
	      .finally(() => {
	        dispatch("loading/end", "viewcreator", { root: true })
	      })
	}
}

// mutations
const mutations = {
	set_view_name(state, name) {
		state.currentView.name = name
	},
	set_loading(state, loading) {
		state.currentView.status.loading = loading
	},
	set_default_template(state, template) {
		state.currentView.template = template
	},
	set_user_template(state, template) {
		state.currentView.user.template = template
	},
	set_builder_registered_components(state, components) {
		state.builder.registered_components = components
	},
	set_builder_available_components(state, components) {
		state.builder.available_components = components
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}