// index.js
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import messagingRoutes from './modules/messaging'

const Login = () => import('@/views/auth/Login')
const MainContainer = () => import("@/core/containers/MainContainer")
const Account = () => import('@/views/auth/Account')
const AccountContainer = () => import("@/views/auth/containers/AccountContainer")

const SuperAdminContainer = () => import("@/views/superadmin/containers/SuperAdminContainer")
const SuperAdmin = () => import("@/views/superadmin/SuperAdmin")
const ClinicOverview = () => import("@/views/superadmin/clinic/ClinicOverview")
const ClinicEdit = () => import("@/views/superadmin/clinic/ClinicEdit")
const ClinicNew = () => import("@/views/superadmin/clinic/ClinicNew")
const UserOverview = () => import("@/views/superadmin/user/UserOverview")
const UserNew = () => import("@/views/superadmin/user/UserNew")
const UserEdit = () => import("@/views/superadmin/user/UserEdit")

const ClinicAdminContainer = () => import("@/views/clinicadmin/containers/ClinicAdminContainer")
const ClinicAdmin = () => import("@/views/clinicadmin/ClinicAdmin")
const ClinicUserOverview = () => import("@/views/clinicadmin/users/UserOverview")
const ClinicUserNew = () => import("@/views/clinicadmin/users/UserNew")
const ClinicUserEdit = () => import("@/views/clinicadmin/users/UserEdit")
const ClinicPortalOverview = () => import("@/views/clinicadmin/portal/Overview")
const ClinicPatientOverview = () => import("@/views/clinicadmin/patients/Overview")

const PortalContainer = () => import("@/views/clinicadmin/containers/PortalContainer")
const PortalHome = () => import("@/views/clinicadmin/portal/Home")
const SurveyContainer = () => import("@/views/clinicadmin/containers/SurveyContainer")
const SurveyHome = () => import("@/views/clinicadmin/survey/Home")
const SurveyComplete = () => import("@/views/clinicadmin/survey/Complete")

const ForgotPassword = () => import('@/views/auth/ForgotPassword')
const ResetPassword = () => import('@/views/auth/ResetPassword')
const ChangePassword = () => import('@/views/auth/ChangePassword')

// Error Pages
const Page401 = () => import('@/views/errors/Page401')
const Page404 = () => import('@/views/errors/Page404')
const Page500 = () => import('@/views/errors/Page500')

const dynamicRoutes = () => {
    store.dispatch('main/fetchRoutes').then((results) => {
        return results
    })
}
const redirectLogout = (to, from, next) => {
    store.dispatch('auth/logout').then(() => next({ path: '/login', replace: true, query: { redirect: from.fullPath } }))
}

const redirectToRoot = (currentUser, to, from, next) => {
    const superadmin = currentUser.roles.some(role => role.name === 'superadmin')
    const clinicadmin = currentUser.roles.some(role => role.name === 'clinicadmin')
    if (superadmin) {
        return next({ path: '/superadmin' })
    }
    if (clinicadmin){
        return next({ path: '/clinicadmin'})
    }

    return next({ path: '/'})
}

// Static routes here
const baseRoutes = [
    {
        path: "/",
        component: MainContainer,
        meta: {
            anonymous: false,
        },
        children: [
            { path: '/login', component: Login, meta: { anonymous: true }},
            { path: '/forgot-password', component: ForgotPassword, meta: { anonymous: true }},
            { path: '/password-reset', component: ResetPassword, meta: { anonymous: true }},
            { path: '/password-change', component: ChangePassword, meta: { bypass: true }},
            { path: '/logout', beforeEnter: redirectLogout },
        ]
    },
    {
        path: '/superadmin',
        component: SuperAdminContainer,
        beforeEnter: (to, from, next) =>{
            const superadmin = store.state.auth.current.roles.some(role => role.name === 'superadmin')
            const researcher = store.state.auth.current.roles.some(role => role.name === 'researcher')
            if (superadmin || researcher || store.state.auth.current.is_superuser) { next() }
            else next('/404')
        },
        meta: { anonymous: false, label: "Superadmin", roles: ['superadmin', 'researcher']},
        children: [
            { path: "", component: SuperAdmin, meta: { anonymous: false, label: "Administration"}},
            { path: "clinic/overview", component: ClinicOverview, meta: { anonymous: false, label: "Clinic Overview"}},
            { path: "clinic/:clinicId/edit/", props: true,  component: ClinicEdit, meta: { anonymous: false, label: "Clinic Edit",params: true}},
            { path: "clinic/new/", component: ClinicNew, meta: { anonymous: false, label: "Clinic Registration"}},
            { path: "user/overview", component: UserOverview, meta: { anonymous: false, label: "User Overview"}},
            { path: "user/new", component: UserNew, meta: { anonymous: false, label: "User Registration"}},
            { path: "user/:userId/edit", props: true,  component: UserEdit, meta: { anonymous: false, label: "User Edit"}},
        ]
    },
    {
        path: '/clinicadmin',
        component: ClinicAdminContainer,
        beforeEnter: (to, from, next) =>{
            const clinicadmin = store.state.auth.current.roles.some(role => role.name === 'clinicadmin')
            const physician = store.state.auth.current.roles.some(role => role.name === 'physician')
            if (clinicadmin || physician || store.state.auth.current.is_superuser) { next() }
            else next('/404')
        },
        meta: { anonymous: false, label: "Clinicadmin", roles: ['clinicadmin', 'physician']},
        children: [
            { path: "", component: ClinicAdmin, meta: { anonymous: false, label: "Clinic Administration"}},
            { path: "user/overview", component: ClinicUserOverview, meta: { anonymous: false, label: "Clinic Users"}},
            { path: "user/new", component: ClinicUserNew, meta: { anonymous: false, label: "Clinic User registration"}},
            { path: "user/:userId/edit", props: true, component: ClinicUserEdit, meta: { anonymous: false, label: "Edit Clinic User"}},
            { path: "portal/overview", component: ClinicPortalOverview, meta: { anonymous: false, label: "Portal Overview"}},
            { path: "patient/overview", component: ClinicPatientOverview, meta: { anonymous: false, label: "Clinic Patients Overview"}},
        ]
    },
    {   path: '/portal',
        name: 'DynamicPortal',
        component: PortalContainer,
        meta: { anonymous: true},
        children: [
            { path: "home", component: PortalHome, meta: { anonymous: true, label: "Clinic portal"}},    
        ]
    },
    {   path: '/survey',
        name: 'DynamicSurvey',
        component: SurveyContainer,
        meta: { anonymous: true},
        children: [
            { path: "home", component: SurveyHome, meta: { anonymous: true, label: "Clinic Survey"}},    
            { path: "complete", component: SurveyComplete, meta: { anonymous: true, label: "Clinic Survey Results"}},    
        ]
    },

    { path: '/401', component: Page401},
    { path: '/404',component: Page404},
    { path: '/500',component: Page500},
    { path: "*", redirect: "/404" },

    // Other predefined static routes...
];

// Merging static routes with dynamically loaded ones
const routes = baseRoutes.concat(messagingRoutes)

Vue.use(Router)

const router = new Router({
    mode: 'history',
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes // Initially load static routes, dynamic ones will be added later
})

router.beforeEach((to, from, next) => {
    // Authentication and role checks here, no changes needed
    const { roles } = to.meta;
    const { anonymous, bypass } = to.meta;
    const currentUser = store.state.auth.current
    if (!currentUser) {
        if (anonymous || bypass) return next()
        if (to.path.includes("portal")) return next()
        if (to.path.includes("survey")) return next()
        if (to.path !== "/login") return next({ path: "/login", query: { redirect: to.fullPath } })
    }

    if (currentUser.force_password_change && !bypass) {
        return next({ path: '/password-change' })
    }

    if (to.fullPath === '/') {        
        return redirectToRoot(currentUser, to, from, next)
    }


    next()
})

export default router
