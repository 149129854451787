<template>
    <Bar :options="chartOptions" :data="chartData" />
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import moment from "moment"

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    props: ['businessHours', 'dataset'],
    data() {
        return {
            chartOptions: {
            responsive: true,
            scales: {
                y: {
                    beginAtZero: true,
                    // Customize Y-axis labels
                    ticks: {
                        stepSize: 5, // Set the step size between labels
                            callback(value, index, values) {
                                return value; // Customize the label as needed
                            }
                        }
                    }
                }
            }
        }
    },
    computed: {
        chartData() {
            return {
                labels: this.generateHourLabels,
                datasets: [ { label: "Patients", backgroundColor: 'rgba(54, 162, 235, 0.2)', borderColor: 'rgb(54, 162, 235)', borderWidth: 1, data: this.dataset,} ]
            }
        },
        generateHourLabels() {
            const currentHour = moment().hour() > moment(this.businessHours?.START, "HH:mm:ss").hour() ? moment().hour() : moment(this.businessHours?.START, "HH:mm:ss").hour()
            const endHour = moment(this.businessHours?.END, "HH:mm:ss").hour()

            const labels = []

            for (let hour = currentHour; hour <= endHour; hour++) {
                const formattedHour = moment().hour(hour).format('h A')
                labels.push(formattedHour)
            }

            return labels
        }
    }
}
</script>